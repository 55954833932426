import React from 'react'



class Index extends React.Component {
    _render(obj){
        return <code>{obj}</code> //here also jsx syntax are valid.
     }
  render() {

    return (
      <div >
    
      </div>
    )
  }
}

export default Index
